<template>
  <div class="wrapper">
    <div class="title"></div>
    <div class="information">
      <div class="prizeArea">
        <div class="bigSmall">
          <span :class="this.arrayNumber[0].bsBG"></span>
          <span :class="this.arrayNumber[1].bsBG"></span>
          <span :class="this.arrayNumber[2].bsBG"></span>
          <span :class="this.arrayNumber[3].bsBG"></span>
          <span :class="this.arrayNumber[4].bsBG"></span>
        </div>
        <div class="number">
          <span>{{this.number[0]}}</span>
          <span>{{this.number[1]}}</span>
          <span>{{this.number[2]}}</span>
          <span>{{this.number[3]}}</span>
          <span>{{this.number[4]}}</span>
        </div>
        <div class="singleDouble">
          <span :class="this.arrayNumber[0].sdBG"></span>
          <span :class="this.arrayNumber[1].sdBG"></span>
          <span :class="this.arrayNumber[2].sdBG"></span>
          <span :class="this.arrayNumber[3].sdBG"></span>
          <span :class="this.arrayNumber[4].sdBG"></span>
        </div>
      </div>
      <div class="displayArea">
        <div class="showBaseInfo">
          <div class="baseInfo">
            <div class="currentTime">本期：<span>{{this.n}}</span>期</div>
            <div class="nextTime">下期开奖： <span>{{nextDate}}</span></div>
            <div class="timeCountDown">倒计时：<span>{{this.time}}</span></div>
          </div>
        </div>
        <div class="showResultInfo">
          <div class="resultInfo">
            <span class="total">{{this.totalResult}}</span>
            <span class="sod">{{this.sdResult}}</span>
            <span class="bos">{{this.bsResult}}</span>
            <span class="animal"></span>
          </div>
          <div class="resultNumber">
            <span>{{this.numberCopy[0]}}</span>
            <span>{{this.numberCopy[1]}}</span>
            <span>{{this.numberCopy[2]}}</span>
            <span>{{this.numberCopy[3]}}</span>
            <span>{{this.numberCopy[4]}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <button class="startButton"></button>
      <button class="voiceButton"></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AoZhouLucky',
    data() {
      return {
        obj: [
          {id: 0, number: 0, bsBG: 'smallBG', sdBG: 'doubleBG'},
          {id: 1, number: 1, bsBG: 'smallBG', sdBG: 'singleBG'},
          {id: 2, number: 2, bsBG: 'smallBG', sdBG: 'doubleBG'},
          {id: 3, number: 3, bsBG: 'smallBG', sdBG: 'singleBG'},
          {id: 4, number: 4, bsBG: 'smallBG', sdBG: 'doubleBG'},
          {id: 5, number: 5, bsBG: 'bigBG', sdBG: 'singleBG'},
          {id: 6, number: 6, bsBG: 'bigBG', sdBG: 'doubleBG'},
          {id: 7, number: 7, bsBG: 'bigBG', sdBG: 'singleBG'},
          {id: 8, number: 8, bsBG: 'bigBG', sdBG: 'doubleBG'},
          {id: 9, number: 9, bsBG: 'bigBG', sdBG: 'singleBG'}
        ],
        arrayNumber: [
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'}
        ],
        number: [], // 存放随机得到的数字
        numberCopy: [],
        time: 20, // 初始倒计时，如果需要修改每期开奖的时间，需要在 getRandomNumber 方法里面修改 time 的时间，并且需要修改 first 方法里面的 id2，id3 的数值
        n: 0, // 期数
        totalResult: 0, // 得到的结果的总数
        sdResult: '', // 得到的结果的总数的单或者双
        bsResult: '', // 得到结果的总数的大或者小

      }
    },
    computed: {
      nextDate: function () {
        let now, show
        now = new Date()
        let h = now.getHours()
        h = h < 10 ? '0' + h : h
        let m = now.getMinutes()
        if (m < 10) {
          m = '0' + m
        } else if (m > 60) {
          m = '0' + m - 60
        } else {
          m = m
        }
        let s = now.getSeconds() + this.time
        if (s < 10) {
          s = '0' + s
        } else if (s > 60) {
          s = '0' + s - 60
          m + 1
        } else {
          s = s
        }
        show = h + ':' + m + ':' + s
        return show
      }
    },
    mounted() {
      this.first()
    },
    methods: {
      init: function () {
        this.arrayNumber = [
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'},
          {bsBG: 'blueBG', sdBG: 'blueBG'}
        ],
          this.number = []
      },
      getRandomNumber: function () {
        if (this.time === 0) {
          let item = []
          this.arrayNumber = []
          this.numberCopy = []
          this.totalResult = 0
          for (let i = 0; i < 5; i++) {
            item = this.obj[Math.floor(Math.random() * 10)]
            this.arrayNumber.push(item)
            this.number.push(item.number)
            this.numberCopy.push(item.number)
            item = []
          }
          for (let i = 0; i < 5; i++) {
            this.totalResult += this.number[i]
          }
          if (this.totalResult % 2 === 0) {
            this.sdResult = '双'
          } else {
            this.sdResult = '单'
          }
          if (this.totalResult > 22) {
            this.bsResult = '大'
          } else {
            this.bsResult = '小'
          }
          this.time = 20  // 如果修改倒计时时间，这里也需要修改，需要跟 time 数值一致
          this.n += 1
        }
      },
      first: function () {
        let id1, id2, id3
        id1 = setInterval(() => {
          this.time--
        }, 1000)

        id2 = setInterval(() => {
          id3 = setTimeout(() => {
            this.init()
          }, 15000) // 如果修改倒计时时间，这里也需要修改（多少时间后会变回蓝色背景）
          this.getRandomNumber()
        }, 20000)  // 如果修改倒计时时间，这里也需要修改，需要跟 time 数值一致

      }
    }
  }
</script>

<style scoped src="./css/AoZhouLucky.css">
</style>

